import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

window.addEventListener("scroll", function () {
  var headerHeight = document.querySelector(
    ".main-header .main-nav-container",
  ).offsetHeight;

  if (window.scrollY > headerHeight + 150) {
    document
      .querySelector(".main-nav-container")
      .classList.add("sticky-navbar");

    document.querySelector(".main-header").classList.add("scrolled");
  } else {
    document
      .querySelector(".main-nav-container")
      .classList.remove("sticky-navbar");

    document.querySelector(".main-header").classList.remove("scrolled");
  }
});

var menuItems = document.getElementById("main-menu").innerHTML;
var mobileMenu = document.querySelector(".mobile-menu-inner");
mobileMenu.innerHTML = menuItems;

document
  .getElementById("mobile-menu-toggle")
  .addEventListener("click", function () {
    document.querySelector(".mobile-menu-container").classList.add("active");
  });

document
  .querySelector("button.close-mobile-menu")
  .addEventListener("click", function () {
    document.querySelector(".mobile-menu-container").classList.remove("active");
  });

document
  .querySelector(".close-mobile-menu.dark-overlay")
  .addEventListener("click", function () {
    document.querySelector(".mobile-menu-container").classList.remove("active");
  });

document
  .querySelectorAll(".inner-menu-toggle")
  .forEach(function (toggleButton) {
    toggleButton.addEventListener("click", function (event) {
      var parentElement = event.target.closest(".menu-item");
      var innerMenuList = parentElement.querySelector(".inner-menu-list");

      innerMenuList.style.maxHeight = innerMenuList.style.maxHeight
        ? null
        : innerMenuList.scrollHeight + "px";

      toggleButton.classList.toggle("active");
    });
  });

document.addEventListener("keydown", function (event) {
  if (event.key === "Escape" || event.key === "Esc") {
    var mobileMenuContainer = document.querySelector(".mobile-menu-container");
    if (mobileMenuContainer) {
      mobileMenuContainer.classList.remove("active");
    }
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var faqAccordion = document.querySelector(".faq-accordion");
  var faqBoxes = faqAccordion.querySelectorAll(".faq-box");

  faqBoxes.forEach(function (box) {
    box.addEventListener("click", function () {
      var value = this.getAttribute("data-value");
      var btn = this.querySelector(".item-btn");
      var content = this.querySelector(".item-content");

      var isActive = this.classList.contains("active");

      // Close all other active faq-box elements and toggle their classes
      faqBoxes.forEach(function (otherBox) {
        if (otherBox !== box && otherBox.classList.contains("active")) {
          var otherContent = otherBox.querySelector(".item-content");
          otherBox.classList.remove("active");
          otherBox.querySelector(".item-btn").classList.remove("active");
          slideToggle(otherContent, 300, true); // Collapse other item-contents
        }
      });

      // Toggle active class on current faq-box
      box.classList.toggle("active");

      // Toggle active class on item-btn
      btn.classList.toggle("active");

      // Toggle active class on item-content and slide
      slideToggle(content, 300);

      // If the clicked FAQ was already active, remove its active state
      if (isActive) {
        content.classList.remove("active");
        slideToggle(content, 300, true); // Collapse content
      }
    });
  });

  // Find and initialize the faq-box with the active class
  var initialActiveBox = faqAccordion.querySelector(".faq-box.active");
  if (initialActiveBox) {
    var initialContent = initialActiveBox.querySelector(".item-content");
    initialContent.style.maxHeight = initialActiveBox.classList.contains(
      "active",
    )
      ? initialContent.scrollHeight + "px"
      : "0";
  }
});

function slideToggle(element, duration, collapse) {
  var maxHeight = element.scrollHeight;
  var originalTransition = element.style.transition;

  element.style.transition = "max-height " + duration + "ms ease-in-out";
  element.style.maxHeight = collapse ? "0" : maxHeight + "px";

  setTimeout(function () {
    element.style.transition = originalTransition;
  }, duration);
}
// document.addEventListener("DOMContentLoaded", () => {});
